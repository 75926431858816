import React, { useEffect, useState } from "react";
import ProductCard from "../Components/ProductCard";
import Axios from "../Axios";
import { useDispatch, useSelector } from "react-redux";
import { updateFilters } from "../Redux/filterSlice";
import Appsection from "../Components/Appsection";
import Adssection from "../Components/Adssection";
import Search from "../Components/Search";
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Errorimg from "../icon/Group 676.svg";

const Home = () => {
  const dispatch = useDispatch();
  // const filers = useSelector((state) => state.filers);
  const category = useSelector((state) => state.category);
  const [selectedcategory, setSelectedcategory] = useState(1);

  const [property, setProperty] = useState([]);
  const [errorData, setErrorData] = useState('');

  const getProperty = async (e) => {
    try {
      const params = {
        perpage: 16,
        category: e,
      };
      setSelectedcategory(e)

      console.log('params ', params);
      const resp = await Axios.get('/property', { params });
      console.log('response ', resp.data);

      if (resp.data.code === "EC200") {
        setProperty(resp.data.data.data);
        setErrorData('');  // Clear error message when valid data is fetched
      } else if (resp.data.code === "EC404") {
        setErrorData("No data found");
        setProperty([]);  // Clear property data on error
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log('404 ', error.response.data.message);
        setErrorData(error.response.data.message);
      } else {
        console.log('error ', error.message);
      }
      setProperty([]);  // Clear property data on error
    }
  };

  useEffect(() => {
    getProperty(1);
  }, []);

  return (
    <>
      <div className="herosection" style={{ backgroundImage: `url('banners/herobanner.png')` }}>
        <div className="background_overlay">
          <Search />
        </div>
      </div>

      <div className="rental_project_category ">
        <div className="container">
          <div className="category_inner">
            <ul>
              {category.slice(0, 8).map((item, ind) => (
                <li key={ind} className={selectedcategory === item.id ? 'active_category' : ''}
                  onClick={() => {getProperty(item.id )   }}>
                  <a>{item.name}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="property_main_wrapper">
        <div className="container">
          <div className="row">
            {errorData && <p className="d-flex justify-content-center result_not_found"><img src={Errorimg} /></p>}
            {property.length === 0 && !errorData && (
              Array.from({ length: 8 }).map((_, index) => (
                <div key={index} className="col-md-4 col-sm-6 col-lg-3 col-12">
                  <Box>
                    <Skeleton height="270px" />
                    <Skeleton />
                    <Skeleton width="60%" />
                  </Box>
                </div>
              ))
            )}
            {property.slice(0, 16).map((item, ind) => (
              <ProductCard key={ind} data={item} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
