import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Axios from '../../../Axios';
import { updateLoader } from '../../../Redux/loaderSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { Modal, Button, Form } from 'react-bootstrap';

const TripsCancel = () => {
    const [selectedReason, setSelectedReason] = useState('');
    const [continuebtn, setContinuebtn] = useState(false);
    const [details, setDetails] = useState(true);
    const [showReasons, setShowReasons] = useState(false);
    const [showCancellationDetails, setShowCancellationDetails] = useState(false);
    const { id } = useParams();
    const [accountHolderName, setAccountHolderName] = useState('');
    const [accountHolderAddress, setAccountHolderAddress] = useState('');
    const [bankName, setBankName] = useState('');
    const [iban, setIban] = useState('');
    const [bicSwift, setBicSwift] = useState('');
    const [data, setData] = useState()
    const [date, setDate] = useState('');
    const [bookingDetails, setBookingDetails] = useState([])
    const dispatch = useDispatch();
    const [textareaValue, setTextareaValue] = useState('');
    const navigate = useNavigate();
    const [cancelData, setCancelData] = useState()
    const handleTextareaChange = (event) => {
        setTextareaValue(event.target.value);
    };
    const handleSaveChanges = () => {
        setShowModal(false);
        handleClick();
    };
    const [showModal, setShowModal] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);
    const handleReasonSelect = (id) => {
        setSelectedReason(id);
        setShowReasons(true); // Hide the reasons section
        setShowCancellationDetails(false);
        setContinuebtn(false) // Show the cancellation details section
    };

    const handleContinueCancel = (e) => {
        e.preventDefault();
        setShowReasons(false);
        setShowCancellationDetails(true);
    };
    const trimDescription = (description, wordLimit) => {
        const words = description.split(' ');
        const trimmedWords = words.slice(0, wordLimit);
        return trimmedWords.join(' ');
    };

    const confirmBookingOnMount = async (id) => {
        try {
            dispatch(updateLoader({ loader: true }));
            const params = {
                order_id: id
            };

            const response = await Axios.post('/booking-confirmation', params);

            if (response.data.code === "EC200") {
                setData(response.data.data.data);
                const timestamp = response.data.data.data.updated_at;
                const dateObj = new Date(timestamp);
                const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                const formattedDate = dateObj.toLocaleDateString('en-US', options);
                setDate(formattedDate);
                dispatch(updateLoader({ loader: false }));
            }
        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response);
            } else {
                console.log(error.message);
            }
        }
    };

    const handleCancelBooking = () => {
        setContinuebtn(true);
        setDetails(false)
    };
    const getBookingDetails = async () => {
        try {
            dispatch(updateLoader({ loader: true }));

            const response = await Axios.get('/reasion-of-booking-cancel');

            if (response.data.code === "EC200") {
                // toast.success(`${response.data.message}`);
                // Handle success scenario if needed
                setBookingDetails(response.data.data.reason)
                console.log(response.data.data.data.reason)

            }

        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            if (error.response && error.response.status === 404) {
                console.log(error.response);
            } else {
                console.log(error.message);
            }
        }
    }


    const handleClick = async () => {
        try {
            dispatch(updateLoader({ loader: true }));
            const response = await Axios.post('/booking-cancel', {
                id: data.id,
                reason: selectedReason,
                tell_us: textareaValue,
                account_holder_name: accountHolderName,
                account_holder_address: accountHolderAddress,
                bank_name: bankName,
                iban: iban,
                bic_swift: bicSwift,
            });
            if (response.data.code === "EC200") {
                // toast.success(`${response.data.message}`);
                // Handle success scenario if needed
                setBookingDetails(response.data.data.reason)
                console.log(response.data.message, "mesage")
                toast.success(`${response.data.data.message}`)
                dispatch(updateLoader({ loader: false }));
                navigate('/user/trips')
            }

        } catch (error) {
            dispatch(updateLoader({ loader: false }));
            toast.error(`${error.response.data.data.message}`)
            if (error.response && error.response.status === 404) {
                console.log(error.response);
            } else {
                console.log(error.message);
            }
        }
    }
    useEffect(() => {
        if (data) {
            const fetchCancellationDetails = async () => {
                try {
                    dispatch(updateLoader({ loader: true }));
                    const response = await Axios.get(`/booking-cancel-details/${data.id}`);
                    if (response.data.code === "EC200") {
                        setCancelData(response.data.data);
                    }
                } catch (error) {
                    console.error('Error fetching cancellation details:', error);
                } finally {
                    dispatch(updateLoader({ loader: false }));
                }
            };

            fetchCancellationDetails();
        }
    }, [data]);
    useEffect(() => {
        getBookingDetails();
        confirmBookingOnMount(id);
    }, [id]);
    const handleBack = () => {
        if (showCancellationDetails) {
            setShowCancellationDetails(false);
            setShowReasons(true);
        } else if (showReasons) {
            setShowReasons(false);
            setContinuebtn(true);
        } else if (continuebtn) {
            setContinuebtn(false);
            setDetails(true);
        } else {
            navigate('/user/trips')
        }
    };
    return (
        <div className='profile_section'>
            <div className='profile_wrapper'>
                <div className="container" style={{ marginTop: '2%' }}>
                    {details ? (
                        <Link to="/user/trips" style={{ display: 'flex', justifyContent: 'left', textDecoration: 'none', marginBottom: '2%' }}>
                            <div className='backarrowbtn' >
                                <i className="fa fa-long-arrow-left"></i>
                            </div>
                            <div className="profilebackbtn" style={{ marginTop: '2px' }}>Trips</div>
                        </Link>
                    ) : (
                        <div onClick={handleBack} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'left', textDecoration: 'none', marginBottom: '2%' }}>
                            <div className='backarrowbtn' >
                                <i className="fa fa-long-arrow-left"></i>
                            </div>
                            <div className="profilebackbtn" style={{ marginTop: '2px' }}>Trips</div>
                        </div>
                    )}
                    <div>
                        {data && details && (
                            <div className='trippage_main_wrapper'>
                                <div className='tripbooking_id_wrapper'>
                                    <div className='tripbookingid'>Booking Id : #{data.id}</div>
                                    <div className='tripbookingid_datetime'>Booked by {data.user.name} on {date}</div>
                                </div>
                                <div className='trip_details_flex_wrapper'>
                                    <div className='trips_details_left'>
                                        <Link to={`/product/${data.product.id}`} className='cancellation_title_tripdetails'>
                                            {data.product.name}
                                        </Link>
                                        <div className='direction_title_wrapper'>
                                            <div className='trips_details_product_dis'>{trimDescription(data.product.description, 10)}...</div>
                                        </div>
                                    </div>
                                    <div className='trips_details_right'>
                                        <div className='trips_details_pitcure'>
                                            <img
                                                src={`${data.product.images[0].url}`}
                                                className='img-fluid'
                                                alt="product"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='trips_details_booking_details'>
                                    <div className='trips_booking_details_wrappar'>
                                        <div className='cancellation_title_tripdetails'>Booking Details</div>
                                        <div className='trips_details_booking_details_flex'>
                                            <div className='trips_details_booking_clumn'>
                                                <div className='booking_details_title_trips'>Primary Guest</div>
                                                <div className='booking_details_sub_title_trips'>{data.user.name}</div>
                                            </div>
                                            <div className='trips_details_booking_clumn'>
                                                <div className='booking_details_title_trips'>Check In</div>
                                                <div className='booking_details_sub_title_trips'>{data.check_in}</div>
                                            </div>
                                            <div className='trips_details_booking_clumn'>
                                                <div className='booking_details_title_trips'>Check In Time</div>
                                                <div className='booking_details_sub_title_trips'>{data.product.check_in_time}</div>
                                            </div>
                                        </div>
                                        <div className='trips_details_booking_details_flex'>
                                            <div className='trips_details_booking_clumn'>
                                                <div className='booking_details_title_trips'>Adult</div>
                                                <div className='booking_details_sub_title_trips'>{data.number_of_guests}</div>
                                            </div>

                                            <div className='trips_details_booking_clumn'>
                                                <div className='booking_details_title_trips'>Check Out</div>
                                                <div className='booking_details_sub_title_trips'>{data.check_out}</div>
                                            </div>
                                            <div className='trips_details_booking_clumn'>
                                                <div className='booking_details_title_trips'>Check Out Time</div>
                                                <div className='booking_details_sub_title_trips'>{data.product.check_out_time}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='trips_details_booking_details_flex'>
                                        <div className='trips_details_booking_clumn'>
                                            <div className='booking_details_title_trips'>Children</div>
                                            <div className='booking_details_sub_title_trips'>{data.children}</div>
                                        </div>
                                        <div className='trips_details_booking_clumn'>
                                            <div className='booking_details_title_trips'>E-mail</div>
                                            <div className='booking_details_sub_title_trips'>{data.user.email}</div>
                                        </div>
                                    </div>
                                    <div className='trip_details_payemnt_mainwrapper'>
                                        <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>€{data.product_price}X {data.book_day} Nights</div>
                                            <div className='details_trip_right_price'>€{data.calculated_price}</div>
                                        </div>
                                        <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>Instant Discount - {data.discount} %</div>
                                            <div className='details_trip_right_price'>€{data.discount_price}</div>
                                        </div>
                                        <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>Service fee</div>
                                            <div className='details_trip_right_price'>€{data.service_fee}</div>
                                        </div>
                                        {data.addons_price > 0 && <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>Addons</div>
                                            <div className='details_trip_right_price'>€{data.addons_price}</div>
                                        </div>}
                                        <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>Tax</div>
                                            <div className='details_trip_right_price'>€{data.tax}</div>
                                        </div>
                                        <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>Payment Mode</div>
                                            <div className='details_trip_right_price'>{data.payment_mode}</div>
                                        </div>
                                        <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>Total Price</div>
                                            <div className='details_trip_right_price'>€{data.total_amount}</div>
                                        </div>
                                    </div>
                                    <div className='cancellation_main_wrapper_trip_details'>
                                        <div className='flex_letus_wrapper'>
                                            <button
                                                className="btn btn-danger"
                                                style={{ marginTop: '1%', color: 'red', backgroundColor: 'white' }}
                                                onClick={handleCancelBooking}
                                            >
                                                Cancel Booking
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {continuebtn && (
                            <div className='trip_details_main_wrapper trippage_main_wrapper'>
                                <div className='reason_cancellation_mainwrapper'>
                                    <div className='reason_cencellation_title'>Reason for cancellation</div>
                                    {bookingDetails.map((item, index) => (
                                        <div
                                            key={index}
                                            className='reason_cancellation_innernwrapper'
                                            onClick={() => handleReasonSelect(item.id)} // Handle row click
                                        >
                                            <div className='reason_cancellation_titletext_left'>
                                                <div className='font_textreason'>
                                                    <i className="fa fa-arrow-circle-o-right" aria-hidden="true"></i> {item.reason}
                                                </div>
                                            </div>
                                            <div className='reason_cancellation_titletext_right'>
                                                <div className='font_textreason_reason_verfy'>
                                                    <input
                                                        type='checkbox'
                                                        checked={selectedReason === item.id}
                                                        readOnly // Make the checkbox read-only as it will be controlled by the row click
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}


                        {showReasons && (
                            <div className='trip_details_main_wrapper trippage_main_wrapper'>
                                <div className='reason_cancellation_mainwrapper'>
                                    <div className='reason_cencellation_title'>Tell us more about it</div>
                                    <form >
                                        <div className='form-group'>
                                            <textarea
                                                className='form-control'
                                                rows='4'
                                                placeholder='Type something here'
                                                value={textareaValue}
                                                onChange={handleTextareaChange}
                                            ></textarea>
                                        </div>
                                        <button type='submit' onClick={handleContinueCancel} className='help_savebtn_tellus'>
                                            Cancel
                                        </button>
                                    </form>
                                    <div className='helpcontactnumber'>
                                        <p>Or Connect Us At: +91 000000000</p>
                                    </div>
                                </div>
                            </div>

                        )}





                        {/* Detailed cancellation information section */}
                        {showCancellationDetails && (
                            <div className='trip_details_main_wrapper trippage_main_wrapper'>
                                <div className='tripbooking_id_wrapper'>
                                    <div className='tripbookingid'>Booking Id : {data.id}</div>
                                    <div className='tripbookingid_datetime'>Booked by {data.user.name} on {date}</div>
                                </div>

                                <div className='trip_details_flex_wrapper'>
                                    <div className='trips_details_left'>
                                        <div className='cancellation_title_tripdetails'>{data.product.name}</div>
                                        <div className='direction_title_wrapper'>
                                            <div className='trips_details_product_dis'>{trimDescription(data.product.description, 10)}...</div>
                                        </div>
                                    </div>

                                    <div className='trips_details_right'>
                                        <div className='trips_details_pitcure'>
                                            <img
                                                src={`${data.product.images[0].url}`}
                                                className='img-fluid'
                                                alt="product"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='trips_details_booking_details'>
                                    <div className='trips_booking_details_wrappar'>
                                        <div class="cancellation_title_tripdetails">Refund Details</div>

                                        <div className='confrim_cencel_main_wrapper_box_wrapper'>
                                            <div className='confrim_cencel_main_wrapper'>
                                                <div className='totalamount_confrom_cencel_left'>Total amount paid</div>
                                                <div className='totalamount_confrom_cencel_right'>{cancelData.order.total_amount}</div>
                                            </div>
                                            <div className='confrim_cencel_main_wrapper'>
                                                <div className='totalamount_confrom_cencel_left'>Cancellation charges ({cancelData.cancelation_price_percent}%)</div>
                                                <div className='totalamount_confrom_cencel_right'>-€{cancelData.cancelation_price}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='trip_details_payemnt_mainwrapper'>
                                        <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>Total Refund</div>
                                            <div className='details_trip_right_price'><strong>{cancelData.refund_price}</strong></div>
                                        </div>
                                        <div className='trip_page_details_payemnt_wrapper'>
                                            <div className='details_trip_left_price_title'>Refund in original payment mode</div>
                                            <div className='details_trip_right_price'><strong>{cancelData.refund_payment_mode}</strong></div>
                                        </div>
                                    </div>

                                    <div className='refund_payemt_text'>
                                        <p>{cancelData.canceltion_message}</p>
                                    </div>

                                    <button type="submit" onClick={handleShow} className="help_savebtn_tellus mt-3">Cancel</button>
                                </div>
                            </div>)}
                        <Modal show={showModal} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Bank Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group controlId="formAccountHolderName">
                                        <Form.Label>Account Holder Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            maxLength="20"
                                            placeholder="Enter account holder name"
                                            value={accountHolderName}
                                            onChange={(e) => setAccountHolderName(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formAccountHolderAddress">
                                        <Form.Label>Account Holder Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            maxLength="30"
                                            placeholder="Enter account holder Number"
                                            value={accountHolderAddress}
                                            onChange={(e) => setAccountHolderAddress(e.target.value)}
                                            onKeyPress={(e) => {
                                                if (!/[0-9]/.test(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />

                                    </Form.Group>
                                    <Form.Group controlId="formBankName">
                                        <Form.Label>Bank Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            maxLength="20"
                                            placeholder="Enter bank name"
                                            value={bankName}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const alphabeticValue = value.replace(/[^a-zA-Z\s]/g, ''); // Removes any non-alphabetic characters
                                                setBankName(alphabeticValue);
                                            }}
                                        />

                                    </Form.Group>
                                    <Form.Group controlId="formIBAN">
                                        <Form.Label>IBAN</Form.Label>
                                        <Form.Control
                                            type="text"
                                            maxLength="34"
                                            placeholder="Enter IBAN"
                                            value={iban}
                                            onChange={(e) => setIban(e.target.value)}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="formBICSWIFT">
                                        <Form.Label>BIC/SWIFT</Form.Label>
                                        <Form.Control
                                            type="text"
                                            maxLength="11"
                                            placeholder="Enter BIC/SWIFT"
                                            value={bicSwift}
                                            onChange={(e) => setBicSwift(e.target.value)}
                                        />
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleSaveChanges} disabled={!accountHolderName || !accountHolderAddress || !bankName || !iban || !bicSwift}
                                    style={accountHolderName === '' || accountHolderAddress === '' || iban === '' || bankName === '' || bicSwift === ''
                                        ? styles.disabledButton : styles.enabledButton}
                                >
                                    Submit Details
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>

                </div >
            </div>
        </div>
    )
}
const styles = {
    disabledButton: {
        backgroundColor: 'grey ',
        border: 'grey',
        color: 'white',
        cursor: 'not-allowed !important',

    },
    enabledButton: {
        background: 'var(--primary)',
        color: 'white',
        cursor: 'pointer',
    },

};

export default TripsCancel
